<template>
    <div class="after-sale">
        <div class="order-head">
            <div style="padding-top: 10px;">
                <div class="select-tag">售后详情</div>
            </div>
            <div>
                <el-dropdown>
                    <div class="hand op sc" style="width: 100%;display: flex" @click="a('/user/info/' + user.uuid)">
                        <img :src="user.headIco" style="width: 30px;height: 30px;border-radius: 50%;padding: 14px 0">
                        <div style="height: 60px;line-height: 60px;font-size: 18px; margin: 0 10px">{{user.nickName}}</div>
                        <div style="font-size: 20px; line-height: 60px; height: 60px;"><i class="el-icon-caret-bottom"></i></div>
                    </div>
                    <el-dropdown-menu slot="dropdown" style="margin-top: -5px;">
                        <div style="width: 130px;height: 300px;background: #FFFFFF;">
                            <div class="hand op sc" style="height: 30px;width: auto;" @click="toOption">
                                <div style="height: 20px;width: 20px;margin: 10px 0 0 20px;float: left;">
                                    <img src="~@/assets/imgs/index/self1.png" style="height: 20px;width: 20px;">
                                </div>
                                <div style="height: 30px;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;">
                                    我的主页
                                </div>
                            </div>
                            <div class="hand op sc" style="height: 50px;width:auto;" @click="toOption">
                                <div style="height: 20px;width: 20px;margin: 23px 0 0 20px;float: left;">
                                    <img src="~@/assets/imgs/index/self2.png" style="height: 20px;width: 20px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);float: left;line-height: 50px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 8px;">
                                    认证中心
                                </div>
                            </div>
                            <div v-if="user.userType == 1" class="hand op sc" style="width: auto;height: 30px;margin-left: 10px;" @click="a('/user/auth/index')" >
                                <div style="height: 17px;width: 17px;margin: 8px 0 0 10px;float: left;">
                                    <img src="~@/assets/imgs/index/self7.png" style="height: 17px;width: 17px;">
                                </div>
                                <span style="width: calc(100% - 48px);height: auto;font-size: 14px;font-weight: bold;cursor: pointer;float: left;margin-left: 5px;margin-top: 8px;">成为专家</span>
                            </div>
                            <div v-if="user.userType == 2" class="hand op sc" style="width: auto;height: 30px;margin-left: 10px;" @click="a('/user/auth/index')" >
                                <div style="height: 17px;width: 17px;margin: 8px 0 0 10px;float: left;">
                                    <img src="~@/assets/imgs/index/self3.png" style="height: 17px;width: 17px;">
                                </div>
                                <span style="width: calc(100% - 48px);height: auto;font-size: 14px;font-weight: bold;cursor: pointer;float: left;margin-left: 5px;margin-top: 8px;">子账号管理</span>
                            </div>
                            <div class="hand op sc" style="height: 50px;width:auto;" @click="a('')">
                                <div style="height: 18px;width: 18px;margin: 20px 0 0 20px;float: left;">
                                    <img src="~@/assets/imgs/index/self4.png" style="height: 18px;width: 18px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);float: left;line-height: 50px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 5px;">
                                    会员中心
                                </div>
                            </div>
                            <div class="hand op sc" style="height: 30px;width: auto;margin-left: 10px;" @click="a('/index/help')">
                                <div style="height: 17px;width: 17px;margin: 3px 0 0 10px;float: left;">
                                    <img src="~@/assets/imgs/index/self5.png" style="height: 17px;width: 17px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);float: left;line-height: 10px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 6px;margin-top: 7px;">
                                    规则中心
                                </div>
                            </div>
                            <div class="hand op sc" style="height: 30px;width:auto;" @click="a('')">
                                <div style="height: 17px;width: 17px;margin: 23px 0 0 20px;float: left;">
                                    <img src="~@/assets/imgs/index/self6.png" style="height: 17px;width: 17px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);float: left;line-height: 50px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 8px;">
                                    帮助中心
                                </div>
                            </div>
                            <div style="width: 130px;border: 1px solid #EEEEEE;float: left;margin-top: 5px;"></div>
                            <div style="position: relative">
                                <div style="height: 30px;position: absolute;left: 20px;top: 40px;">
                                    <el-dropdown-item command="/index/index">退出登录</el-dropdown-item>
                                </div>
                            </div>
                        </div>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <div class="order-body">
            <div style="background: #FFFFFF; margin: 0 auto;">
                <ul style="display: flex;">
                    <li v-for="(item,index) in flowList" :key="index" class="info">
                        <!--  圆圈节点  -->
                        <div :class="{active: index  < goodData.status}" class="primary"><i :class="item.icon"></i></div>
                        <!--  线   -->
                        <div :class="{active: index  < goodData.status - 1}" class="error" v-if="index <= 3"></div>
                        <!--  标注    -->
                        <div class="dark">{{ item.title }}</div>
<!--                        <div v-if="index === 0" style="font-size: 14px; color: #999999; margin-top: 2px">2023-04-15  08:20:59</div>-->
                    </li>
                </ul>
            </div>
        </div>

        <div class="order-body">
            <div style="display: flex; justify-content: space-between">
                <div class="title-name">
                    商品详情
                </div>
                <div style="display: flex">
                    <span>店铺：</span>
                    <span>{{goodData.shopName}}</span>
                </div>
            </div>

            <div>
                <AfterCard :orderData="goodData"></AfterCard>
            </div>

            <div v-if="showType === true">
                <div class="option">
                    <div @click="type = 1" v-if="goodData.isPay === 1" class="card hand op" :class="{'select-border': type === 1}" style="margin-right: 50px">
                        <img src="./imgs/refund.png" class="icon">
                        <div>
                            <div class="card-title">我要退款</div>
                            <div class="card-content">未收到货，或与商家协商之后退款</div>
                        </div>
                        <div class="triangle" v-if="type === 1">
                            <i class="el-icon-check tr-icon"></i>
                        </div>
                    </div>
                    <div @click="type = 2" v-if="goodData.isPay === 3" class="card hand op" :class="{'select-border': type === 2}" style="margin-right: 50px">
                        <img src="./imgs/reGoods.png" class="icon">
                        <div>
                            <div class="card-title">我要退货</div>
                            <div class="card-content">已收到货，需要退还已收到的货物</div>
                        </div>
                        <div class="triangle" v-if="type === 2">
                            <i class="el-icon-check tr-icon"></i>
                        </div>
                    </div>
                    <div @click="type = 3" v-if="goodData.isPay === 3" class="card hand op" :class="{'select-border': type === 3}">
                        <img src="./imgs/return.png" class="icon">
                        <div>
                            <div class="card-title">我要换货</div>
                            <div class="card-content">已收到货，需要更换该商品</div>
                        </div>
                        <div class="triangle" v-if="type === 3">
                            <i class="el-icon-check tr-icon"></i>
                        </div>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; margin-bottom: 40px">
                    <div class="hand op btn-next" style="background-color: #F5F5F5; color: #999999">上一步</div>
                    <div class="hand op btn-next" v-if="type === 0" style="background-color: #F5F5F5; color: #999999">下一步</div>
                    <div class="btn-next" v-else @click="showType = false" style="background-color: #2970FF; color: #FFFFFF">下一步</div>
                </div>
            </div>

            <div v-if="showType === false" style="padding: 40px 0">
                <AfterFlow @returnBack="toBack" @reData="getGoodInfo" v-if="showType === false && user.uuid === goodData.uuid" :orderData="goodData" :type="type"></AfterFlow>
                <AfterManage @reData="getGoodInfo" v-if="showType === false && user.uuid === goodData.shopUuid" :orderData="goodData"></AfterManage>
            </div>

        </div>
    </div>
</template>

<script>

import AfterCard from "./components/order/AfterCard";
import AfterFlow from "./components/order/AfterFlow";
import AfterManage from "./components/order/AfterManage";
export default {
    name: "afterSale",
    components: {AfterManage, AfterFlow, AfterCard},
    data() {
        return {
            flowList: [
                {
                    status: 1,
                    title: '选择申请类别',
                    icon: 'el-icon-check'
                },
                {
                    status: 2,
                    title: '填写申请详情',
                    icon: 'el-icon-check'
                },
                {
                    status: 3,
                    title: '商家处理申请',
                    icon: 'el-icon-check'
                },
                {
                    status: 4,
                    title: '等待服务操作',
                    icon: 'el-icon-check'
                },
                {
                    status: 5,
                    title: '售后服务完成',
                    icon: 'el-icon-check'
                }
            ],

            status: 2,
            type: 0,

            showType: true,

            goodData: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getGoodInfo()
    },
    methods: {
        // 子组件上一步
        toBack() {
            this.type = 0;
            this.showType = true
        },

        // 获取商品详情
        getGoodInfo() {
            this.newApi.shopGoodsOrderInfo({smallOrderNo: this.$route.query.smallOrderNo}).then(res => {
                if (res.isSuccess === 1) {
                    if (JSON.stringify(res.data.isAftersale) !== '{}') {
                        this.showType = false;
                    }
                    this.goodData = res.data;
                }
            })
        },

        // 跳转
        toOption(){
            var that = this;
            if(that.user.userType === 1){
                that.$router.push('/circle/mySpace/' + that.user.uuid)
            }else if (that.user.userType === 2 || that.user.userType === 3)
                that.$router.push('/circle/organSpace/' + that.user.uuid)
        },
    }
}
</script>

<style scoped>
    .after-sale {
        height: 100vh;
        background-color: #F2F2F2;
    }

    .after-sale .order-head {
        width: calc(100% - 60px);
        height: 60px;
        font-size: 16px;
        padding: 0 60px 0 0;
        background-color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .after-sale .order-head .select-tag {
        font-size: 20px;
        font-weight: 600;
        padding: 8px 0 0 0;
        height: 48px;
        margin-left: 920px;
        border-radius: 10px 10px 0 0;
    }

    .after-sale .order-body {
        margin: 0 auto;
        width: 1420px;
        padding: 30px 40px;
        margin-bottom: 10px;
        background-color: #FFFFFF;
    }

    .dark {
        margin-top: 10px;
        color: #444444;
        font-size: 18px;
    }

    .info {
        display: inline-block;
        width: 320px;
    }

    .primary {
        box-sizing: border-box;
        border-radius: 50%;
        cursor: pointer;
        color: #2970FF;
        background-color: #E8F2FB;
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin: auto 30px;
        line-height: 34px;
    }

    .primary.active {
        background-color: #FFFFFF !important;
        border: 3px #2970FF solid;
        border-radius: 50%;
    }

    .error.active{
        border-top: 10px solid #2970FF;
    }

    .error {
        width: 300px;
        margin-left: 70px;
        margin-top: -25px;
        height: 10px;
        border-top: 10px solid #E8F2FB;
    }

    .after-sale .title-name {
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        margin-bottom: 10px;
    }

    .after-sale .option {
        display: flex;
        margin-top: 50px;
        margin-bottom: 180px;
    }

    .after-sale .card {
        width: 360px;
        height: 50px;
        display: flex;
        padding: 30px 40px;
        position: relative;
        background-color: #FFFFFF;
        box-shadow: 0 1px 6px 1px rgba(0,0,0,0.16);
    }

    .after-sale .card .icon {
        width: 48px;
        height: 50px;
        margin-right: 20px;
    }

    .after-sale .card .card-title {
        font-size: 18px;
        font-weight: 600;
        color: #2970FF;
        margin-bottom: 2px;
    }

    .after-sale .card .card-content {
        font-weight: 600;
        color: #999999;
    }

    .after-sale .select-border {
        border: 2px solid #2970FF;
    }

    .after-sale .triangle{
        position:absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border: 16px solid #fff;
        border-bottom-color: #2970FF;
        border-right-color: #2970FF;
        border-top-color:transparent ;
        border-left-color: transparent;
    }

    .after-sale .tr-icon {
        color: #FFFFFF;
        position:absolute;
        font-weight: 600;
    }

    .after-sale .btn-next {
        padding: 10px 50px;
        font-weight: 600;
        border-radius: 5px;
        margin-right: 50px;
    }
</style>
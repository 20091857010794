<template>
    <div class="after-flow">
        <div class="after-body">
            <div class="body-left">
                <el-form ref="form" style="padding-right: 30px" :model="afterInfo" :rules="afterRules">
                    <div style="display: flex; margin-bottom: 30px">
                        <el-form-item label="申请原因：" prop="reason" style="margin-right: 40px" label-width="100px">
                            <el-select :disabled="JSON.stringify(orderData.isAftersale) !== '{}'" v-model="afterInfo.reason" size="small" style="width:270px" placeholder="请选择">
                                <el-option v-for="(item, index) in refundOption" :key="index" :label="item.content" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择邮寄方式：" prop="post" label-width="130px" v-if="status === 1">
                            <el-select v-model="afterInfo.post" size="small" style="width:270px" placeholder="请选择">
                                <el-option v-for="(item, index) in refundOption" :key="index" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="退款金额：" prop="money" label-width="130px">
                            <el-input v-model="orderData.totalAmount" size="small" disabled style="width: 270px">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div style="margin-bottom: 30px;">
                        <el-form-item label="申请说明：" prop="explain" label-width="100px">
                            <el-input type="textarea" :disabled="JSON.stringify(orderData.isAftersale) !== '{}'" placeholder="补充说明具体原因" rows="6" v-model="afterInfo.explain"></el-input>
                        </el-form-item>
                    </div>

                    <div style="margin-bottom: 30px;">
                        <el-form-item label="上传凭证：" :prop="type === 1 ? '' : 'images'" label-width="100px">
                            <div style="display: flex; flex-wrap: wrap">
                                <el-upload v-if="JSON.stringify(orderData.isAftersale) === '{}'" class="upload-demo" drag multiple
                                           action="https://jsonplaceholder.typicode.com/posts/"
                                           :show-file-list="false" :http-request="uploadImg"
                                            style="margin-right: 14px">
                                    <div style="margin-top: 50px">
                                        <i class="el-icon-plus icon-plus" style="color: #2970FF;font-size: 30px; font-weight: 600"></i>
                                        <div>点击上传图片</div>
                                        <div style="font-size: 12px; color: #999999; line-height: 0">（最多9张）</div>
                                    </div>
                                </el-upload>
                                <div v-for="(item, index) in afterInfo.images" :key="index" style="position: relative; border:1px solid #c1c1c1; width:172px; height: 172px; border-radius: 5px; margin: 0 10px 10px 0">
                                    <div class="hand op" v-if="JSON.stringify(orderData.isAftersale) === '{}'" style="background:#ff7575;color:#FFF;width:20px;height:20px;text-align: center; line-height:20px;border-radius:10px;position: absolute; top: 0; right: 0" @click="removeImg(index)">
                                        <i class="el-icon-close"></i>
                                    </div>
                                    <img :src="item" style="width: 100%; height: 100%">
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>

            <!--申请退款-->
            <div class="body-right" style="width: 600px; position: relative" v-if="orderData.isAftersale.type === 1 || type === 1">
                <div v-if="JSON.stringify(orderData.isAftersale) === '{}'">
                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 10px">
                        发起退货退款申请后需要等待商家处理
                    </div>
                    <div style="color: #999999">
                        <p>如果商家同意退款申请，将立即退款</p>
                        <p>如果商家超时未处理，系统将直接退款给您</p>
                        <p>如果商家拒绝，您可以修改申请再次发起，商家会重新处理</p>
                    </div>
                    <div style="display: flex;position: absolute; bottom: 20px; left: 110px">
                        <div class="hand op btn-next" @click="toBack" style="background-color: #F5F5F5; color: #999999">上一步</div>
                        <div @click="submitApply" class="btn-next hand op" style="background-color: #2970FF; color: #FFFFFF">提交申请</div>
                    </div>
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 0">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        您已发起退款申请，请耐心等待商家处理
                    </div>
                    <div style="color: #999999">
                        <p>如果商家同意退款申请，将立即退款</p>
                        <p>如果商家超时未处理，系统将直接退款给您</p>
                        <p>如果商家拒绝，您可以修改申请再次发起，商家会重新处理</p>
                    </div>
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 1">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        商家同意退款申请，退款成功<span style="font-size: 16px; color: #999999">（退款返回至原银行卡内）</span>
                    </div>
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 2">
                    <div style="font-size: 18px; color: #FF0000; font-weight: 600; margin-bottom: 10px">
                        商家拒绝退款申请<span style="font-size: 16px; color: #999999">（具体原因请联系商家）</span>
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">拒绝说明：{{orderData.isAftersale.refundReason}}</div>
                    </div>
                </div>
            </div>

            <!--申请退货-->
            <div class="body-right" style="width: 600px; position: relative" v-if="orderData.isAftersale.type === 2 || type === 2">
                <div v-if="JSON.stringify(orderData.isAftersale) === '{}'">
                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 10px">
                        发起退货申请后需要等待商家处理
                    </div>
                    <div style="color: #999999">
                        <p>如果商家同意退货退款申请，请按照商家反馈的地址将货品寄出</p>
                        <p>如果商家超时未处理，系统将直接退款给您</p>
                        <p>如果商家拒绝，您可以修改申请再次发起，商家会重新处理</p>
                    </div>
                    <div style="display: flex;position: absolute; bottom: 20px; left: 110px">
                        <div class="hand op btn-next" @click="toBack" style="background-color: #F5F5F5; color: #999999">上一步</div>
                        <div @click="submitApply" class="btn-next hand op" style="background-color: #2970FF; color: #FFFFFF">提交申请</div>
                    </div>
                </div>

                <!--已发起退货-->
                <div v-if="orderData.isAftersale.refundIsAgree === 0">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 2px">
                        您已发起退货退款申请，请耐心等待商家处理
                    </div>
                    <div style="color: #999999">
                        如果商家同意退货退款申请，请按照商家反馈的地址将货品寄出
                        如果商家超时未处理，系统将直接退款给您
                        如果商家拒绝，您可以修改申请再次发起，商家会重新处理
                    </div>
                </div>

                <div v-if="orderData.isAftersale.refundIsAgree === 4">
                    <div style="font-size: 18px; color: #FF0000; font-weight: 600; margin-bottom: 10px">
                        商家拒绝退货退款申请<span style="font-size: 16px; color: #999999">（具体原因请联系商家）</span>
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">拒绝说明：{{orderData.isAftersale.refundReason}}</div>
                    </div>
                </div>

                <!--退换货邮寄-->
                <div v-if="orderData.isAftersale.refundIsAgree === 3">
                    <div style="font-size: 18px; color: #67C23A; font-weight: 600; margin-bottom: 10px">
                        商家同意了您的退货退款申请，请尽快按照地址将货品寄出
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">收货人：<span style="color: #444444">{{orderData.isAftersale.consignee}}</span></div>
                        <div style="margin-bottom: 2px">联系电话：<span style="color: #444444">{{orderData.isAftersale.phone}}</span></div>
                        <div style="margin-bottom: 2px">
                            收货地址：
                            <span style="color: #444444">
                                {{ orderData.isAftersale.refundAddress.cityName[0] }}
                                {{ orderData.isAftersale.refundAddress.cityName[1] }}
                                {{ orderData.isAftersale.refundAddress.cityName[2] }}
                                {{ orderData.isAftersale.refundAddress.address }}
                            </span></div>
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 4px">注意：</div>
                        <div style="margin-bottom: 2px">1.未与商家协商一致，请勿使用到付或平邮，以免商家拒签货物</div>
                        <div style="margin-bottom: 2px">2.交易的欠款还在百工宝中间账户，确保您资金安全</div>
                        <div style="margin-bottom: 2px">3.请填写真实物流信息，逾期未填写，退货申请将撤销</div>
                    </div>
                    <div style="margin-top: 20px">
                        填写物流单号：
                        <el-input :disabled="!(!orderData.isAftersale.refundOrder)" v-model="refundOrder" style="width: 340px">
                            <template slot="append">物流公司</template>
                        </el-input>
                    </div>
                    <div style="display: flex;justify-content: center; margin-top: 50px" v-if="!orderData.isAftersale.refundOrder">
                        <div @click="sureSend(1)" class="btn-next hand op" style="background-color: #2970FF; color: #FFFFFF">我已寄出</div>
                    </div>
                    <div v-else style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">
                        <div style="padding: 10px">物流信息</div>
                    </div>
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 5">
                    <div style="font-size: 18px; color: #67C23A; font-weight: 600; margin-bottom: 10px">
                        商家已收到货物，退款成功
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">商家验货无误，已确认退款给您，退款将返回至原银行卡内</div>
                    </div>
                    <div style="margin-top: 20px">
                        物流单号：
                        <el-input v-model="orderData.isAftersale.refundOrder" disabled style="width: 340px">
                            <template slot="append">物流公司</template>
                        </el-input>
                    </div>
                    <div style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">
                        <div style="padding: 10px">物流信息</div>
                    </div>
                </div>
            </div>

            <!--申请换货-->
            <div class="body-right" style="width: 600px; position: relative" v-if="orderData.isAftersale.type === 3 || type === 3">
                <div v-if="JSON.stringify(orderData.isAftersale) === '{}'">
                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 2px">
                        发起换货申请后需要等待商家处理
                    </div>
                    <div style="color: #999999">
                        如果商家同意换货申请，请按照商家反馈的地址将货品寄出
                        如果商家拒绝，您可以修改后再次发起申请，商家会重新处理
                    </div>
                    <div style="display: flex;position: absolute; bottom: 20px; left: 110px">
                        <div class="hand op btn-next" @click="toBack" style="background-color: #F5F5F5; color: #999999">上一步</div>
                        <div @click="submitApply" class="btn-next hand op" style="background-color: #2970FF; color: #FFFFFF">提交申请</div>
                    </div>
                </div>

                <!--已发起换货货-->
                <div v-if="orderData.isAftersale.refundIsAgree === 0">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 2px">
                        您已发起换货申请，请耐心等待商家处理
                    </div>
                    <div style="color: #999999">
                        如果商家同意换货申请，请按照商家反馈的地址将货品寄出
                        如果商家超时未处理，系统将直接退款给您
                        如果商家拒绝，您可以修改申请再次发起，商家会重新处理
                    </div>
                </div>

                <!--换货邮寄-->
                <div v-if="orderData.isAftersale.refundIsAgree === 6">
                    <div style="font-size: 18px; color: #67C23A; font-weight: 600; margin-bottom: 10px">
                        商家同意了您的换货申请，请尽快按照地址将货品寄出
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">收货人：<span style="color: #444444">{{orderData.isAftersale.consignee}}</span></div>
                        <div style="margin-bottom: 2px">联系电话：<span style="color: #444444">{{orderData.isAftersale.phone}}</span></div>
                        <div style="margin-bottom: 2px">
                            收货地址：
                            <span style="color: #444444">
                                {{ orderData.isAftersale.refundAddress.cityName[0] }}
                                {{ orderData.isAftersale.refundAddress.cityName[1] }}
                                {{ orderData.isAftersale.refundAddress.cityName[2] }}
                                {{ orderData.isAftersale.refundAddress.address }}
                            </span></div>
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 4px">注意：</div>
                        <div style="margin-bottom: 2px">1.未与商家协商一致，请勿使用到付或平邮，以免商家拒签货物</div>
                        <div style="margin-bottom: 2px">2.交易的欠款还在百工宝中间账户，确保您资金安全</div>
                        <div style="margin-bottom: 2px">3.请填写真实物流信息，逾期未填写，退货申请将撤销</div>
                    </div>
                    <div style="margin-top: 20px">
                        填写物流单号：
                        <el-input :disabled="!(!orderData.isAftersale.refundOrder)" v-model="refundOrder" style="width: 340px">
                            <template slot="append">物流公司</template>
                        </el-input>
                    </div>
                    <div style="display: flex;justify-content: center; margin-top: 50px" v-if="!orderData.isAftersale.refundOrder">
                        <div @click="sureSend(2)" class="btn-next hand op" style="background-color: #2970FF; color: #FFFFFF">我已寄出</div>
                    </div>
                    <div v-else style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">
                        <div style="padding: 10px">物流信息</div>
                    </div>
                </div>
            </div>

            <!--商家收货 处理订单-->
            <div class="body-right" style="width: 600px; position: relative" v-if="type === 4">
                <div v-if="status === 1">
                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 10px">
                        请等待卖家收货并退款
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">如果商家收到货并验货无误，将操作退款给您</div>
                        <div style="margin-bottom: 2px">如果商家在<span style="color: #FF0000">08天18时30分47秒</span>内未处理，系统将直接退款给您</div>
                        <div style="margin-bottom: 2px">如果商家拒绝退款，需要您修改退货申请重新提交</div>
                    </div>
                </div>
                <div v-if="status === 2">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        商家已收到货物，退款成功
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">商家验货无误，已确认退款给您，退款将返回至原银行卡内</div>
                    </div>
                </div>

                <div style="margin-top: 20px">
                    填写物流单号：
                    <el-input v-model="flowNumber" disabled style="width: 340px">
                        <template slot="append">物流公司</template>
                    </el-input>
                </div>
                <div style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">
                    <div style="padding: 10px">物流信息</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "AfterFlow",
    components: {},
    props: {
        orderData: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            afterInfo: {
                reason: '',
                post: '',
                explain: '',
                images: [],
            },
            afterRules: {
                reason: [
                    {required: true, message: '请选择申请原因', trigger: 'change'}
                ],
                post: [
                    {required: true, message: '请选择邮寄方式', trigger: 'change'}
                ],
                explain: [
                    {required: true, message: '请输入申请说明', trigger: 'blur'}
                ],
                images: [
                    {required: true, message: '请上传凭证', trigger: 'blur'}
                ],
            },
            refundOption: [],
            status: 2,

            refundOrder: '', //物流单号
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getRefundList()
        if (this.orderData.isAftersale.refundOrder) {
            this.refundOrder = this.orderData.isAftersale.refundOrder
        }
    },
    methods: {
        // 获取退款原因
        getRefundList() {
            this.newApi.goodsRefundReason({type: 1}).then(res => {
                this.refundOption = res.data
                if (JSON.stringify(this.orderData.isAftersale) !== '{}') {
                    this.afterInfo.reason = res.data.find(object => object.id === this.orderData.isAftersale.reasonType).content;
                    this.afterInfo.explain = this.orderData.isAftersale.refundRemark;
                    this.afterInfo.images = this.orderData.isAftersale.images;
                }
            })
        },

        // 上一步
        toBack() {
            this.$emit('returnBack')
        },

        // 确认寄出
        sureSend(type) {
            if (!this.refundOrder) {
                this.utils.err('请填写物流单号');
                return false;
            }
            this.newApi.buyeUpdateGoodsOrder({aftersaleType: type, smallOrderNo: this.orderData.smallOrderNo, refundOrder: this.refundOrder}).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$emit('reData')
                }
            })
        },

        // 提交申请
        submitApply() {
            var that = this;
            that.$refs['form'].validate(function (valid) {
                if (valid) {
                    let params = {};
                    params.type = that.type;
                    params.smallOrderNo = that.orderData.smallOrderNo;
                    params.reasonType = that.afterInfo.reason;
                    params.refundRemark = that.afterInfo.explain;
                    params.images = JSON.stringify(that.afterInfo.images)
                    that.newApi.goodsRefund(params).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data)
                            that.$emit('reData')
                        }
                    })
                } else {
                    return false;
                }
            })
        },

        // 上传图片
        uploadImg(params) {
            var that = this;
            var file = params.file;
            const isLt5M = file.size / 1024 / 1024 < 20;
            if (isLt5M) {
                if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                    that.utils.upload(file,function(url){
	                    if (!url){
		                    return false;
	                    }
                        if (that.afterInfo.images.length >= 9) {
                            that.$message.error('最多上传9张图片!')
                            return false;
                        }
                        that.afterInfo.images.push(url.url);
                    })
                } else {
                    this.$message.error('只能上传图片类型!')
                }
            } else {
                this.$message.error('上传文件大小不能超过 20MB!')
            }
        },

        // 移除图片
        removeImg(index) {
            var that = this;
            that.afterInfo.images.splice(index, 1);
        },
    }
}
</script>

<style scoped>
    .after-flow {

    }

    .after-flow .after-body {
        display: flex;
    }

    .after-flow .body-left {
        width: 1160px;
        border-right: 1px solid #BDBFC1
    }

    .after-flow .body-right {
        width: 400px;
        padding: 10px 0 10px 30px;
    }

    .after-flow .btn-next {
        padding: 8px 40px;
        font-weight: 600;
        border-radius: 5px;
        margin-right: 20px;
    }

    /deep/ .upload-demo .el-upload-dragger{
        width: 172px;
        height: 172px;
    }

</style>

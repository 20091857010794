<template>
    <div class="after-card">
        <div class="title-info">
            <div style="display: flex">
                <div>
                    成交时间：{{orderData.createTime}}
                </div>
                <div style="margin: 0 120px 0 40px">
                    订单号：{{orderData.smallOrderNo}}
                </div>
                <div style="margin: 0 66px 0 50px">
                    单价
                </div>
                <div style="margin: 0 86px 0 40px">
                    数量
                </div>
                <div style="margin: 0 114px 0 40px">
                    金额
                </div>
                <div style="margin: 0 80px 0 50px">
                    订单状态
                </div>
                <div style="margin: 0 0 0 40px">
                    操作
                </div>
            </div>
        </div>

        <div class="body-box">
            <div style="padding: 20px; display: flex;">
                <img v-if="orderData.skuList" :src="orderData.skuList.images[0]" style="width: 90px; height: 90px; margin-right: 10px;">
                <div style="width: 458px">
                    <div class="content-bord">{{orderData.goodsName}}</div>
                    <div class="content-normal" style="margin-top: 10px" v-if="orderData.skuList">
                        <span v-for="(key, index) in orderData.skuList.specValues" :key="index">
                                {{Object.keys(key)[0]}}: {{Object.values(key)[0]}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="body-option" style="width: 160px; border-right: 1px solid #E9E9E9;">￥{{orderData.unitPrice}}</div>
            <div class="body-option" style="width: 120px; border-right: 1px solid #E9E9E9;">{{orderData.count}}</div>


            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;" v-if="JSON.stringify(orderData) !== '{}'">
                <div class="content-bord">￥{{utils.keepTwoDecimalFull(orderData.unitPrice * orderData.count)}}</div>
                <div class="content-normal">（含运费：￥0.00）</div>
            </div>

            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">
                <div v-for="(type, index) in orderStatusList" :key="index" v-if="orderData.isAftersale && JSON.stringify(orderData.isAftersale) !== '{}'">
                    <span class="content-status" :style="{color: type.color}" v-if="orderData.isAftersale.refundIsAgree === type.status">{{type.name}}</span>
                </div>
            </div>

            <div class="body-option" style="width: 130px">
                <div class="hand op" v-if="user.uuid === orderData.uuid" style="color: #031F88; font-weight: 600;" @click="toMessage(orderData.shopUuid, orderData.shopName)">
                    <i class="el-icon-service"></i> 联系商家
                </div>
                <div class="hand op" v-if="user.uuid === orderData.shopUuid" style="color: #031F88; font-weight: 600;" @click="toMessage(orderData.uuid, '买家')">
                    <i class="el-icon-service"></i> 联系买家
                </div>
            </div>
        </div>

        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import ToMessage from "./ToMessage";
export default {
    name: "AfterCard",
    components: {ToMessage},
    data() {
        return {
            orderStatusList: [
                {name: '待处理', color: '#F44336', status: 0},
                {name: '同意退款', color: '#8BC34A', status: 1},
                {name: '拒绝退款', color: '#F44336', status: 2},
                {name: '同意退货', color: '#8BC34A', status: 3},
                {name: '拒绝退货', color: '#F44336', status: 4},
                {name: '退货完成', color: '#8BC34A', status: 5},
                {name: '同意换货', color: '#8BC34A', status: 6},
                {name: '拒绝换货', color: '#F44336', status: 7},
                {name: '换货完成', color: '#8BC34A', status: 8},
            ],

            showMessage: false,
            uuid: '',
            nickName: '',
        }
    },
    props: {
        orderData: {
            type: Object,
            default() {
                return {
                    status: 2
                }
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {
        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            this.nickName = name;
            this.showMessage = true
        },
    }
}
</script>

<style scoped>
.after-card {
    background-color: #FFFFFF;
    border: 1px solid #BDBFC1;
}

.after-card .title-info {
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    color: #999999
}

.after-card .body-box {
    background-color: #FFFFFF;
    display: flex;
}

.after-card .content-bord {
    font-size: 16px;
    font-weight: 600;
    text-overflow:ellipsis;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    overflow:hidden;
    display:-webkit-box;
}

.after-card .content-normal {
    font-size: 14px;
    color: #999999;
}

.content-status {
    font-size: 14px;
    margin-bottom: 6px;
    text-align: center;
}

.after-card .body-option {
    text-align: center;
    font-weight: 600;
    padding-top: 20px;
}

.dialog-title {
    height: 30px;
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

/deep/ .show-message .el-dialog__header{
    padding: 0;
}

/deep/ .show-message .el-dialog__body{
    padding: 0;
}
</style>

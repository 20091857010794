<template>
    <div class="after-manage">
        <div class="after-body">
            <div class="body-left">
                <el-form ref="form" style="padding-right: 30px" :model="afterInfo">
                    <div style="display: flex; margin-bottom: 30px">
                        <el-form-item label="申请原因：" prop="reason" style="margin-right: 40px" label-width="100px">
                            <el-select :disabled="JSON.stringify(orderData.isAftersale) !== '{}'" v-model="afterInfo.reason" size="small" style="width:270px" placeholder="请选择">
<!--                                <el-option v-for="(item, index) in refundOption" :key="index" :label="item.content" :value="item.id"></el-option>-->
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择邮寄方式：" prop="post" label-width="130px" v-if="status === 1">
                            <el-select v-model="afterInfo.post" size="small" style="width:270px" placeholder="请选择">
                                <el-option v-for="(item, index) in refundOption" :key="index" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="退款金额：" prop="money" label-width="130px">
                            <el-input v-model="orderData.totalAmount" size="small" disabled style="width: 270px">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div style="margin-bottom: 30px;">
                        <el-form-item label="申请说明：" prop="explain" label-width="100px">
                            <el-input type="textarea" :disabled="JSON.stringify(orderData.isAftersale) !== '{}'" placeholder="补充说明具体原因" rows="6" v-model="afterInfo.explain"></el-input>
                        </el-form-item>
                    </div>

                    <div style="margin-bottom: 30px;">
                        <el-form-item label="上传凭证：" prop="images" label-width="100px">
                            <div style="display: flex; flex-wrap: wrap">
                                <el-upload v-if="JSON.stringify(orderData.isAftersale) === '{}'" class="upload-demo" drag multiple
                                           action="https://jsonplaceholder.typicode.com/posts/"
                                           :show-file-list="false" :http-request="uploadImg"
                                           style="margin-right: 14px">
                                    <div style="margin-top: 50px">
                                        <i class="el-icon-plus icon-plus" style="color: #2970FF;font-size: 30px; font-weight: 600"></i>
                                        <div>点击上传图片</div>
                                        <div style="font-size: 12px; color: #999999; line-height: 0">（最多9张）</div>
                                    </div>
                                </el-upload>
                                <div v-for="(item, index) in afterInfo.images" :key="index" style="position: relative; border:1px solid #c1c1c1; width:172px; height: 172px; border-radius: 5px; margin: 0 10px 10px 0">
                                    <div class="hand op" v-if="JSON.stringify(orderData.isAftersale) === '{}'" style="background:#ff7575;color:#FFF;width:20px;height:20px;text-align: center; line-height:20px;border-radius:10px;position: absolute; top: 0; right: 0" @click="removeImg(index)">
                                        <i class="el-icon-close"></i>
                                    </div>
                                    <img :src="item" style="width: 100%; height: 100%">
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>

            <!--处理退款-->
            <div class="body-right" style="width: 600px; position: relative" v-if="orderData.isAftersale.type === 1">
                <div v-if="orderData.isAftersale.refundIsAgree === 0">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        买家已发起退款申请，请尽快处理
                    </div>

                    <div style="color: #999999">
                        <p>如果您同意退款，请点击确认按钮，系统会自动退款给买家</p>
                        <p>如果您拒绝退款申请，买家可以申请客服介入</p>
                        <p>如果您在<span style="color: #FF0000">08天18时30分47秒</span>内未处理，系统将自动同意退款</p>
                    </div>
                    <div style="margin: 30px 0">
                        处理申请：
                        <el-radio v-model="optionType" :label="1">同意退款</el-radio>
                        <el-radio v-model="optionType" :label="2">拒绝退款</el-radio>
                    </div>
                    <div v-if="optionType === 1">
<!--                        <div>-->
<!--                            <span style="vertical-align: top">退款说明：</span>-->
<!--                            <el-input type="textarea" placeholder="填写退款说明" rows="5" style="width: 80%" v-model="refundContent"></el-input>-->
<!--                        </div>-->
                        <div style="display: flex; justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" @click="disposeApply(1)" style="border: 1px solid #67C23A; color: #67C23A">同意退款</div>
                        </div>
                    </div>

                    <div v-if="optionType === 2">
<!--                        <div style="margin-bottom: 20px">-->
<!--                            拒绝原因：-->
<!--                            <el-select v-model="refundReason" size="small" style="width:270px" placeholder="请选择">-->
<!--                                <el-option label="区域一" value="shanghai"></el-option>-->
<!--                                <el-option label="区域二" value="beijing"></el-option>-->
<!--                            </el-select>-->
<!--                        </div>-->
                        <div>
                            <span style="vertical-align: top">拒绝说明：</span>
                            <el-input type="textarea" placeholder="填写拒绝退款说明" rows="5" style="width: 80%" v-model="refundReason"></el-input>
                        </div>
                        <div style="display: flex; justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" @click="disposeApply(2)" style="border: 1px solid #FF3F3F; color: #FF3F3F">拒绝退款</div>
                        </div>
                    </div>
<!--                    <div style="position: absolute; bottom: 20px; left: 200px">-->
<!--&lt;!&ndash;                        <div class="hand op btn-next" style="background-color: #F5F5F5; color: #999999">上一步</div>&ndash;&gt;-->
<!--                        <div @click="submitApply" class="btn-next hand op" style="background-color: #2970FF; color: #FFFFFF">提交</div>-->
<!--                    </div>-->
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 1">
                    <div style="font-size: 18px; color: #67C23A; font-weight: 600; margin-bottom: 10px">
                        已同意退款，系统将自动退款给买家
                    </div>
<!--                    <div style="color: #999999; margin-bottom: 10px">-->
<!--                        <div style="margin-bottom: 2px">退款说明：同意退款，未收到快递请拒收</div>-->
<!--                    </div>-->
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 2">
                    <div style="font-size: 18px; color: #FF0000; font-weight: 600; margin-bottom: 10px">
                        已拒绝退款申请
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
<!--                        <div style="margin-bottom: 2px">拒绝原因：其他</div>-->
                        <div style="margin-bottom: 2px">拒绝说明：{{orderData.isAftersale.refundReason}}</div>
                    </div>
                </div>
            </div>

            <!--处理退货退款-->
            <div class="body-right" style="width: 600px; position: relative" v-if="orderData.isAftersale.type === 2">
                <div v-if="orderData.isAftersale.refundIsAgree === 0">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        买家已发起退货退款申请，请尽快处理
                    </div>
                    <div style="color: #999999">
                        <p>如果您同意退货退款，请将正确退货地址提供给买家</p>
                        <p>如果您拒绝退货退款申请，买家可以申请客服介入</p>
                        <p>如果您在<span style="color: #FF0000">7天</span>内未处理，系统将自动同意退款，并
                            把当前交易地址提供给买家</p>
                    </div>
                    <div style="margin: 30px 0">
                        处理申请：
                        <el-radio v-model="optionType" :label="1">同意退货退款</el-radio>
                        <el-radio v-model="optionType" :label="2">拒绝退货退款</el-radio>
                    </div>
                    <div v-if="optionType === 1">
                        <div style="margin-bottom: 10px">
                            <span style="vertical-align: top">收货人：</span>
                            <el-input size="small" type="text" placeholder="填写收货人" style="width: 80%" v-model="name"></el-input>
                        </div>
                        <div style="margin-bottom: 10px">
                            <span style="vertical-align: top">联系电话：</span>
                            <el-input size="small" type="text" placeholder="填写收货人联系电话" style="width: 80%" v-model="phone"></el-input>
                        </div>
                        <div>
                            <span>退货地址：</span>
                            <AddressSelect @change="selectCity" style="margin-top: 10px" :address ="address" :lv="3" :info="true" :multiple="false"></AddressSelect>
                        </div>
                        <div style="display: flex; justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" @click="disposeReturn(1)" style="border: 1px solid #67C23A; color: #67C23A">同意退货退款</div>
                        </div>
                    </div>
                    <div v-if="optionType === 2">
                        <div>
                            <span style="vertical-align: top">拒绝说明：</span>
                            <el-input type="textarea" placeholder="填写拒绝退款说明" rows="5" style="width: 80%" v-model="refundReason"></el-input>
                        </div>
                        <div style="display: flex; justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" @click="disposeReturn(2)" style="border: 1px solid #FF3F3F; color: #FF3F3F">拒绝退货退款</div>
                        </div>
                    </div>
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 3">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        已同意退款，等待买家将商品寄回
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">收到买家退货时，请验货后再确认收货，收货后将退款</div>
                    </div>
                    <div v-if="orderData.isAftersale.refundOrder">
                        <div style="color: #999999; margin-bottom: 10px">
                            <div style="margin-bottom: 2px">收货人：<span style="color: #444444">{{orderData.isAftersale.consignee}}</span></div>
                            <div style="margin-bottom: 2px">联系电话：<span style="color: #444444">{{orderData.isAftersale.phone}}</span></div>
                            <div style="margin-bottom: 2px">
                                收货地址：
                                <span style="color: #444444">
                                {{ orderData.isAftersale.refundAddress.cityName[0] }}
                                {{ orderData.isAftersale.refundAddress.cityName[1] }}
                                {{ orderData.isAftersale.refundAddress.cityName[2] }}
                                {{ orderData.isAftersale.refundAddress.address }}
                            </span></div>
                        </div>
                        <div style="margin-top: 20px">
                            物流单号：
                            <el-input v-model="orderData.isAftersale.refundOrder" disabled style="width: 340px">
                                <template slot="append">物流公司</template>
                            </el-input>
                        </div>
                        <div style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">
                            <div style="padding: 10px">物流信息</div>
                        </div>
                        <div style="display: flex;justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" @click="ReceiveGoods" style="background-color: #2970FF; color: #FFFFFF">确认收货</div>
                        </div>
                    </div>
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 4">
                    <div style="font-size: 18px; color: #FF0000; font-weight: 600; margin-bottom: 10px">
                        已拒绝退货退款申请
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">拒绝说明：{{orderData.isAftersale.refundReason}}</div>
                    </div>
                </div>
                <div v-if="orderData.isAftersale.refundIsAgree === 5">
                    <div style="font-size: 18px; color: #67C23A; font-weight: 600; margin-bottom: 10px">
                        退货退款已完成
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">验货无误，已确认退款给买家，退款将返回至买家原银行卡内</div>
                    </div>
                    <div style="margin-top: 20px">
                        物流单号：
                        <el-input v-model="orderData.isAftersale.refundOrder" disabled style="width: 340px">
                            <template slot="append">物流公司</template>
                        </el-input>
                    </div>
                    <div style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">
                        <div style="padding: 10px">物流信息</div>
                    </div>
                </div>
            </div>

            <!--处理换货-->
            <div class="body-right" style="width: 600px; position: relative" v-if="orderData.isAftersale.type === 3">
<!--                选择-->
                <div v-if="orderData.isAftersale.refundIsAgree === 0">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        买家已发起换货申请，请尽快处理
                    </div>

                    <div style="color: #999999">
                        <p>如果您同意换货，请点击确认按钮，将正确的地址给买家</p>
                        <p>如果您拒绝换货，买家可以申请客服介入</p>
                        <p>如果您在<span style="color: #FF0000">7天</span>内未处理，系统将自动同意换货&
                            维修，并把当前交易地址提供给买家</p>
                    </div>
                    <div style="margin: 30px 0">
                        处理申请：
                        <el-radio v-model="optionType" :label="1">同意换货</el-radio>
                        <el-radio v-model="optionType" :label="2">拒绝换货</el-radio>
                    </div>
                    <div v-if="optionType === 1">
                        <div style="margin-bottom: 10px">
                            <span style="vertical-align: top">收货人：</span>
                            <el-input type="text" size="small" placeholder="填写收货人" style="width: 80%" v-model="name"></el-input>
                        </div>
                        <div style="margin-bottom: 10px">
                            <span style="vertical-align: top">联系电话：</span>
                            <el-input type="text" size="small" placeholder="填写收货人联系电话" style="width: 80%" v-model="phone"></el-input>
                        </div>
                        <div>
                            <span>退货地址：</span>
                            <AddressSelect @change="selectCity" style="margin-top: 10px" :address ="address" :lv="3" :info="true" :multiple="false"></AddressSelect>
                        </div>
                        <div style="display: flex; justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" @click="disposeChange(1)" style="border: 1px solid #67C23A; color: #67C23A">同意换货</div>
                        </div>
                    </div>
                    <div v-if="optionType === 2">
                        <div>
                            <span style="vertical-align: top">拒绝说明：</span>
                            <el-input type="textarea" placeholder="填写拒绝退款说明" rows="5" style="width: 80%" v-model="refundReason"></el-input>
                        </div>
                        <div style="display: flex; justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" @click="disposeChange(2)" style="border: 1px solid #FF3F3F; color: #FF3F3F">拒绝换货</div>
                        </div>
                    </div>
                </div>
<!--                同意-->
                <div v-if="orderData.isAftersale.refundIsAgree === 6">
                    <div style="font-size: 18px; color: #2970FF; font-weight: 600; margin-bottom: 10px">
                        已同意换货，等待买家将商品寄回
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">收到买家换货时，请验货后再确认收货</div>
                    </div>
                    <div v-if="orderData.isAftersale.refundOrder">
                        <div style="margin-top: 20px">
                            物流单号：
                            <el-input v-model="orderData.isAftersale.refundOrder" disabled style="width: 340px">
                                <template slot="append">物流公司</template>
                            </el-input>
                        </div>
                        <div style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">
                            <div style="padding: 10px">物流信息</div>
                        </div>
                        <div style="display: flex;justify-content: center; margin-top: 40px">
                            <div class="btn-next hand op" style="background-color: #2970FF; color: #FFFFFF">确认收货</div>
                        </div>
                    </div>
                </div>
                <!--                拒绝-->
                <div v-if="orderData.isAftersale.refundIsAgree === 7">
                    <div style="font-size: 18px; color: #FF0000; font-weight: 600; margin-bottom: 10px">
                        已拒绝换货申请
                    </div>
                    <div style="color: #999999; margin-bottom: 10px">
                        <div style="margin-bottom: 2px">拒绝说明：{{orderData.isAftersale.refundReason}}</div>
                    </div>
                </div>
<!--                寄回-->
<!--                <div v-if="status === 1">-->
<!--                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 10px">-->
<!--                        已确认收货，请及时将换货&维修的货物寄出-->
<!--                    </div>-->
<!--                    <div style="color: #999999; margin-bottom: 10px">-->
<!--                        <div style="margin-bottom: 4px">注意：</div>-->
<!--                        <div style="margin-bottom: 2px">1.未与商家协商一致，请勿使用到付或平邮，以免商家拒签货物</div>-->
<!--                        <div style="margin-bottom: 2px">2、请填写真实物流信息，确保买家能收到货物</div>-->
<!--                    </div>-->
<!--                    <div style="margin-top: 20px">-->
<!--                        物流单号：-->
<!--                        <el-input v-model="flowNumber" style="width: 340px">-->
<!--                            <template slot="append">物流公司</template>-->
<!--                        </el-input>-->
<!--                    </div>-->
<!--                    <div style="display: flex;justify-content: center; margin-top: 50px">-->
<!--                        <div @click="sureSend" class="btn-next" style="background-color: #2970FF; color: #FFFFFF">我已寄出</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                已寄回，等待买家收货-->
<!--                <div v-if="status === 1">-->
<!--                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 10px">-->
<!--                        已寄出换货&维修的货物，等待买家收货-->
<!--                    </div>-->
<!--                    <div style="margin-bottom: 2px">商家已完成换货&维修，等待买家收到货物</div>-->
<!--                    <div style="margin-top: 20px">-->
<!--                        填写物流单号：-->
<!--                        <el-input v-model="flowNumber" disabled style="width: 340px">-->
<!--                            <template slot="append">物流公司</template>-->
<!--                        </el-input>-->
<!--                    </div>-->
<!--                    <div style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">-->
<!--                        <div style="padding: 10px">物流信息</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                完成-->
<!--                <div v-if="status === 1">-->
<!--                    <div style="font-size: 18px; color: #67C23A; font-weight: 600; margin-bottom: 10px">-->
<!--                        服务完成-->
<!--                    </div>-->
<!--                    <div style="color: #999999; margin-bottom: 10px">-->
<!--                        <div style="margin-bottom: 2px">买家已收到换货&维修的货物，服务完成</div>-->
<!--                    </div>-->
<!--                    <div style="margin-top: 20px">-->
<!--                        填写物流单号：-->
<!--                        <el-input v-model="flowNumber" disabled style="width: 340px">-->
<!--                            <template slot="append">物流公司</template>-->
<!--                        </el-input>-->
<!--                    </div>-->
<!--                    <div style="height: 300px; width: 460px; overflow: auto; border: 1px solid #BDBFC1; margin-top: 20px">-->
<!--                        <div style="padding: 10px">物流信息</div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

        </div>
    </div>
</template>

<script>

import AddressSelect from "../../../../components/common/city/AddressSelect";
export default {
    name: "AfterManage",
    components: {AddressSelect},
    data() {
        return {
            afterInfo: {
                reason: '',
                post: '',
                explain: '',
                images: [],
                money: '',
            },

            optionType: 1,  // 同意拒绝退款
            refundContent: '',  // 同意说明
            refundReason: '',  // 拒绝原因
            address: {},  // 收货地址
            name: '',
            phone: '',

            status: 10,

            flowNumber: '', //物流单号
        }
    },
    props: {
        orderData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getRefundList()
    },
    methods: {
        // 商家确认收货
        ReceiveGoods() {
            this.newApi.shopReceiveGoods({smallOrderNo: this.orderData.smallOrderNo}).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$emit('reData')
                }
            })
        },

        // 处理退款
        disposeApply(type) {
            var that = this;
            that.openLoading("退款中,请稍等");
            let params = {};
            params.type = type;
            params.smallOrderNo = this.orderData.smallOrderNo
            if (type === 2) {
                params.refundReason = this.refundReason
            }
            this.newApi.shopRefundHandle(params).then(res => {
                if (res.isSuccess === 1) {
                    that.closeLoading();
                    this.utils.sus(res.data);
                    this.$emit('reData')
                }
            })
        },

        // 处理退货
        disposeReturn(type) {
            let params = {};
            params.type = type;
            params.smallOrderNo = this.orderData.smallOrderNo;
            if (type === 2) {
                params.refundReason = this.refundReason
            }
            if (type === 1) {
                if (!this.name) {
                    this.utils.err('请填写收货人姓名');
                    return;
                }
                if (!this.phone) {
                    this.utils.err('请填写收货人联系电话');
                    return;
                }
                params.consignee = this.name;
                params.phone = this.phone;
                params.refundAddress = JSON.stringify(this.address)
            }
            this.newApi.shopReturnGoods(params).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$emit('reData')
                }
            })
        },

        // 处理换货
        disposeChange(type) {
            let params = {};
            params.type = type;
            params.smallOrderNo = this.orderData.smallOrderNo;
            if (type === 2) {
                params.refundReason = this.refundReason
            }
            if (type === 1) {
                if (!this.name) {
                    this.utils.err('请填写收货人姓名');
                    return;
                }
                if (!this.phone) {
                    this.utils.err('请填写收货人联系电话');
                    return;
                }
                if (JSON.stringify(this.address) === '{}') {
                    this.utils.err('请填写收货地址');
                    return;
                }
                params.consignee = this.name;
                params.phone = this.phone;
                params.refundAddress = JSON.stringify(this.address)
            }
            this.newApi.shopExchangeGoods(params).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$emit('reData')
                }
            })
        },

        // 获取退款原因
        getRefundList() {
            this.newApi.goodsRefundReason({type: 1}).then(res => {
                this.refundOption = res.data
                if (JSON.stringify(this.orderData.isAftersale) !== '{}') {
                    this.afterInfo.reason = res.data.find(object => object.id === this.orderData.isAftersale.reasonType).content;
                    this.afterInfo.explain = this.orderData.isAftersale.refundRemark;
                    this.afterInfo.images = this.orderData.isAftersale.images;
                    this.afterInfo.money = this.orderData.isAftersale.refundPrice;
                }
            })
        },

        // 确认寄出
        sureSend() {
            if (!this.flowNumber) {
                this.utils.err('请填写物流单号');
                return false;
            }
        },


        // 提交申请
        submitApply() {
            var that = this;
            that.$refs['form'].validate(function (valid) {
                if (valid) {
                    console.log(valid)
                } else {
                    return false;
                }
            })
        },

        // 选择地址
        selectCity(item) {
            this.address = item
        },

        // 上传图片
        uploadImg(params) {
            var that = this;
            var file = params.file;
            const isLt5M = file.size / 1024 / 1024 < 20;
            if (isLt5M) {
                if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                    that.utils.upload(file,function(url){
	                    if (!url){
		                    return false;
	                    }
                        if (that.afterInfo.images.length >= 9) {
                            that.$message.error('最多上传9张图片!')
                            return false;
                        }
                        that.afterInfo.images.push(url);
                    })
                } else {
                    this.$message.error('只能上传图片类型!')
                }
            } else {
                this.$message.error('上传文件大小不能超过 20MB!')
            }
        },

        // 移除图片
        removeImg(index) {
            var that = this;
            that.afterInfo.images.splice(index, 1);
        },
    }
}
</script>

<style scoped>
.after-manage {

}

.after-manage .after-body {
    display: flex;
}

.after-manage .body-left {
    width: 1160px;
    border-right: 1px solid #BDBFC1
}

.after-manage .body-right {
    width: 400px;
    padding: 10px 0 10px 30px;
}

.after-manage .btn-next {
    padding: 8px 40px;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 20px;
}

/deep/ .upload-demo .el-upload-dragger{
    width: 172px;
    height: 172px;
}

</style>
